import React from 'react';
import {Text, TextSize} from '../../../text';
import styles from './testimony-comment-footer.module.scss';

interface TestimonyCommentFooterProps {
  index: number;
}

const names = ['Katrina Johnson-Holloway', '@Herroareena', 'Dr. Quentin J. Lee'];

const occupationLangKeys = ['pmwjs_testimony_title_music_promoter', 'pmwjs_testimony_title_blogger', 'pmwjs_testimony_title_principal'];

export function TestimonyCommentFooter({index}: TestimonyCommentFooterProps) {
  return (
    <div className={`${styles.container} flex-justify-between`}>
      <div className={styles.name}>
        <Text val={names[index]} size={TextSize.SMALL} bold className={styles.nameText} />
      </div>

      <div className={styles.title}>
        <Text val={window.i18next.t(occupationLangKeys[index])} className={styles.titleText} />
      </div>
    </div>
  );
}
