// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import type {LoginPageProps} from '@Components/login-page';
import {LoginPage} from '@Components/login-page';
import type {LoginPageIosProps} from '@Components/login-page-ios';
import {LoginPageIos} from '@Components/login-page-ios';
import {Provider} from 'react-redux';
import App from '@Components/app';
import {createRoot} from 'react-dom/client';
import ModalContainerDeprecated from '@Components/modal-container-deprecated/modal-container';
import {ModalContainer} from '@Components/modal-container';
import {isUserLoggedIn} from '@Libraries/user.library';
import '@Global/modal';
import type {LOGIN_OPTIONS} from '@Libraries/login-signup-library';

/**
 *
 * @param containerElement
 * @param opts
 */
function renderLoginSignupPage(containerElement: HTMLElement | null, opts: LoginPageProps): void {
  if (!containerElement) {
    return;
  }

  const root = createRoot(containerElement);
  root.render(
    <Provider store={window.PMW.redux.store}>
      <LoginPage {...opts} />
    </Provider>
  );
}

function renderIOSLoginSignupPage(containerElement: HTMLElement | null, opts: LoginPageIosProps): void {
  if (!containerElement) {
    return;
  }

  const root = createRoot(containerElement);
  root.render(
    <Provider store={window.PMW.redux.store}>
      <LoginPageIos {...opts} />
    </Provider>
  );
}

function initLoginSignupPage(): void {
  const loginContainer = document.getElementById('login-sign-up-page');
  const messageType = loginContainer.querySelector('input[name="messageType"]').value as string;
  const isIOSApp = loginContainer.querySelector('input[name="isIOSApp"]').value as boolean;

  let showErrorMessage = false;
  if (messageType === 'error' || messageType === 'warning') {
    showErrorMessage = true;
  }

  let isTeamWelcomeMessage = false;
  if (messageType === 'team-welcome') {
    isTeamWelcomeMessage = true;
  }

  const loginPageProps: LoginPageProps = {
    loginOptionsMode: loginContainer.querySelector('input[name="loginOptionsMode"]')?.value as LOGIN_OPTIONS,
    loginActionType: loginContainer.querySelector('input[name="loginActionType"]')?.value as string,
    signupActionType: loginContainer.querySelector('input[name="signupActionType"]')?.value as string,
    isInDataRegulatedLocation: loginContainer.querySelector('input[name="dataRegulatedLocation"]')?.value as boolean,
    referrer: loginContainer.querySelector('input[name="referrer"]').value as string,
    teamName: '',
    encryptedTeamName: '',
    idTeam: '',
    invite: '',
    redirectUrl: loginContainer.querySelector('input[name="redirecturl"]').value as string,
    clientName: '',
    clientId: '',
    responseType: '',
    redirectUri: '',
    state: '',
    message: loginContainer.querySelector('input[name="message"]').value as string,
    showErrorMessage,
    isTeamWelcomeMessage,
  };

  if (isUserLoggedIn() && window.PMW.util.doesUrlContainEmbeddedEditorAuthenticationLayoverUri(window.location.href)) {
    window.close();
  }

  if (loginContainer.querySelector('input[name="idTeam"]') !== null) {
    loginPageProps.teamName = loginContainer.querySelector('input[name="teamName"]').value as string;
    loginPageProps.invite = loginContainer.querySelector('input[name="invite"]').value as string;
    loginPageProps.idTeam = loginContainer.querySelector('input[name="idTeam"]').value as string;
    loginPageProps.encryptedTeamName = loginContainer.querySelector('input[name="encryptedTeamName"]').value as string;
  }

  if (loginContainer.querySelector('input[name="clientId"]') !== null) {
    loginPageProps.clientName = loginContainer.querySelector('input[name="clientName"]').value as string;
    loginPageProps.clientId = loginContainer.querySelector('input[name="clientId"]').value as string;
    loginPageProps.responseType = loginContainer.querySelector('input[name="responseType"]').value as string;
    loginPageProps.redirectUri = loginContainer.querySelector('input[name="redirectUri"]').value as string;
    loginPageProps.state = loginContainer.querySelector('input[name="state"]').value as string;
  }

  if (isIOSApp) {
    renderIOSLoginSignupPage(loginContainer, loginPageProps);
  } else {
    renderLoginSignupPage(loginContainer, loginPageProps);
  }
}

const a = document.createElement('div');
a.setAttribute('id', 'pmw-app');
document.body.appendChild(a);

const root = createRoot(document.getElementById('pmw-app')!);
root.render(
  <App>
    <ModalContainerDeprecated />
    <ModalContainer />
  </App>
);

window.PMW.initPage = (): void => {
  window.PMW.initI18next(window.PMW.LANGUAGE, initLoginSignupPage);
  window.PMW.initTracking();
};
