import type {ReactElement, ReactNode} from 'react';
import React, {useEffect, useState} from 'react';
import {BLOGGER_TESTIMONY_INDEX, MUSIC_PROMOTER_TESTIMONY_INDEX, PRINCIPAL_TESTIMONY_INDEX, redirectToPage} from '@Libraries/login-signup-library';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {getAssetUrl} from '@Utils/s3.util';
import {LoginSignupPageAssetName} from '@Components/login-page/login-page.types';
import styles from './login-signup-testimony.module.scss';
import {Text} from '../text';
import {PostermywallNavigationLogo} from '../postermywall-navigation-logo';
import {TestimonySlideButtons} from './components/testimony-slide-buttons';
import {TestimonyTransition} from './components/testimony-transition';

const TOTAL_TESTIMONIES = 3;
const TIME_DELAY_FOR_AUTOMATIC_TESTIMONY_CHANGE = 6000;

interface LoginSignupTestimonyProps {
  showTestimonies: boolean;
  doesReferrerExist: boolean;
}

function LoginSignupTestimony({showTestimonies, doesReferrerExist}: LoginSignupTestimonyProps): ReactElement {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [doesUserControlAnimation, setDoesUsercontrolAnimation] = useState(false);

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty('--background-image', `url('${getAssetUrl(LoginSignupPageAssetName.BACKGROUND_TESTIMONY_SHAPES)}')`);

    root?.style.setProperty('--background-image-transition', `url('${getAssetUrl(LoginSignupPageAssetName.BACKGROUD_TESTIMONY_SHAPES_TRANSITION)}')`);
  }, []);

  useEffect(() => {
    if (doesUserControlAnimation) {
      return;
    }

    const timer = setInterval(() => {
      setCurrentIndex((currentIndex) => {
        return getNewIndex(currentIndex);
      });
    }, TIME_DELAY_FOR_AUTOMATIC_TESTIMONY_CHANGE);
    return (): void => {
      clearInterval(timer);
    };
  }, [doesUserControlAnimation]);

  const getNewIndex = (testimonyIndex: number): number => {
    if (testimonyIndex === TOTAL_TESTIMONIES - 1) {
      return 0;
    }
    return testimonyIndex + 1;
  };

  const updateCarousel = (): void => {
    if (doesUserControlAnimation) {
      setCurrentIndex(getNewIndex(currentIndex));
    } else {
      setDoesUsercontrolAnimation(true);
    }
  };

  const onSlideButtonClick = (e: React.MouseEvent, newIndex: number): void => {
    e.stopPropagation();
    setCurrentIndex(newIndex);
  };

  const getClassForTestimony = (testimonyIndex: number): string => {
    if (testimonyIndex === currentIndex) {
      return styles.fadeInTestimony;
    }
    return styles.fadeOutTestimony;
  };

  const getTestimonies = (): ReactNode => {
    if (!showTestimonies) {
      return <></>;
    }

    return (
      <>
        <div className={styles.nonLogoItemsContainer}>
          <div className={`${styles.headingContainer} flexbox flex-items-center`}>
            <Text val={window.i18next.t('pmwjs_login_page_marketing_welcome')} className={styles.heading} />
          </div>

          <div className={styles.transitionContainer}>
            <div className={getClassForTestimony(MUSIC_PROMOTER_TESTIMONY_INDEX)}>
              <TestimonyTransition index={MUSIC_PROMOTER_TESTIMONY_INDEX} />
            </div>
            <div className={getClassForTestimony(BLOGGER_TESTIMONY_INDEX)}>
              <TestimonyTransition index={BLOGGER_TESTIMONY_INDEX} />
            </div>
            <div className={getClassForTestimony(PRINCIPAL_TESTIMONY_INDEX)}>
              <TestimonyTransition index={PRINCIPAL_TESTIMONY_INDEX} />
            </div>
          </div>
        </div>
        <div className="flex-row-justify-center">
          <TestimonySlideButtons index={currentIndex} totalTestimonies={TOTAL_TESTIMONIES} onClick={onSlideButtonClick} />
        </div>
      </>
    );
  };

  const showBackIcon = (): boolean => {
    return doesReferrerExist && showTestimonies && !window.PMW.util.doesUrlContainEmbeddedEditorAuthenticationLayoverUri(window.location.href);
  };

  const onBackIconClick = (): void => {
    redirectToPage(window.PMW.util.homepage_url());
  };

  const getBackIcon = (): ReactNode | null => {
    if (!showBackIcon()) {
      return null;
    }

    return (
      <Icon
        icon="icon-back"
        type={IconType.NONE}
        shape={IconShape.SQUARE}
        size={IconSize.SIZE_ICON_20}
        className={`spacing-m-t-4 spacing-m-l-4 ${styles.backIcon}`}
        onClick={onBackIconClick}
      />
    );
  };

  const getLogoClassNames = (): string => {
    const classNames = styles.logo;
    return showBackIcon() ? `${classNames} ${styles.iconVisible}` : classNames;
  };

  return (
    <div className={`${styles.container} flex-v-row`}>
      <div className={styles.backgroundContainer}>
        <div className={styles.backgroundOverlay} />
      </div>

      <div className={`flex-column-justify-center ${styles.contentContainer}`} onClick={updateCarousel}>
        <div>
          {getBackIcon()}
          <PostermywallNavigationLogo imageUrl={window.PMW.util.asset_url('images/logo.png')} containerClassName={getLogoClassNames()} imageClassName={styles.logoImg} />
        </div>

        {getTestimonies()}
      </div>
    </div>
  );
}

export default React.memo(LoginSignupTestimony);
