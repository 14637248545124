import React, {useEffect} from 'react';
import styles from './testimony-slide-buttons.module.scss';

/**
 @author Talha Ashar <talha@250mils.com>
 */

interface TestimonySlideButtonsProps {
  index: number;
  totalTestimonies: number;
  onClick: Function;
}

export function TestimonySlideButtons({index, totalTestimonies, onClick}: TestimonySlideButtonsProps) {
  const testimonyIndices = new Array(totalTestimonies).fill(null).map((_, i) => {
    return i;
  });

  const ACTIVE_PANEL_OPACITY = '1';
  const HIDDEN_PANEL_OPACITY = '0.3';

  useEffect(() => {
    for (let i = 0; i < totalTestimonies; i++) {
      const selector = `.${styles.slideButtonsContainer} ` + `div:eq(${i})`;
      let opacity = HIDDEN_PANEL_OPACITY;

      if (i === index) {
        opacity = ACTIVE_PANEL_OPACITY;
      }

      $(selector).css('opacity', opacity);
    }
  }, [index]);

  const getButtonClassName = (i: number): string => {
    if (i === index) {
      return styles.selected;
    }
    return styles.hidden;
  };

  return (
    <div className={`${styles.slideButtonsContainer} flex-row-justify-center`}>
      {testimonyIndices.map((i) => {
        return (
          <div
            key={i}
            className={`${styles.slideButton} spacing-m-r-3 ${getButtonClassName(i)}`}
            onClick={(e) => {
              onClick(e, i);
            }}
          />
        );
      })}
    </div>
  );
}
