import type {ReactElement} from 'react';
import React from 'react';
import styles from './login-panel-heading-ios.module.scss';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {noop} from '@Utils/general.util';
import {ClickableDiv} from '@Components/clickable-div';

interface LoginPanelHeadingIosProps {
  showBackOption: boolean;
  heading: string;
  linkActionInfo?: string;
  linkText?: string;
  changePanelLayout?: VoidFunction;
  switchPanel?: VoidFunction;
}

export function LoginPanelHeadingIos({
  showBackOption,
  heading,
  linkText = '',
  linkActionInfo = '',
  changePanelLayout = noop,
  switchPanel = noop,
}: LoginPanelHeadingIosProps): ReactElement | null {
  const getPanelHeadingWithBackOption = (): ReactElement => {
    return (
      <div className={`flexbox ${styles.backHeadingContainer}`}>
        <Icon
          icon="icon-back"
          size={IconSize.SIZE_ICON_20}
          type={IconType.TRANSPARENT}
          shape={IconShape.SQUARE}
          onClick={changePanelLayout}
          className={`spacing-m-r-3 ${styles.iconContainer}`}
        />
        <div className="flex-center">
          <Text val={heading} size={TextSize.LARGE} bold />
        </div>
      </div>
    );
  };

  const getPanelHeadingWithoutBackOption = (): ReactElement => {
    return (
      <div className="spacing-m-b-7 flex-column-align-center">
        <Text val={heading} size={TextSize.LARGE} bold />
        <div className="flexbox">
          <Text val={linkActionInfo} size={TextSize.XSMALL} />
          <ClickableDiv className={`spacing-m-l-1 flex-row-justify-center ${styles.signupLink}`} onClick={switchPanel}>
            <Text val={linkText} size={TextSize.XSMALL} />
          </ClickableDiv>
        </div>
      </div>
    );
  };

  if (showBackOption) {
    return getPanelHeadingWithBackOption();
  }
  return getPanelHeadingWithoutBackOption();
}
