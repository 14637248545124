import type {ReactElement, ReactNode} from 'react';
import React, {useState} from 'react';
import {Icon} from '@Components/icon-v2';
import {Text, TextSize} from '@Components/text';
import {GoogleLoginButton} from '@Components/google-login-button';
import {ButtonFacebookLogin} from '@Components/button-facebook-login';
import {LineDivider} from '@Components/line-divider';
import {
  areThirdPartyButtonsNeededForType,
  AUTH_TYPE,
  doesAuthTypeNeedBackButton,
  isLoginOrSignupAuthType,
  isOAuthFlow,
  isSignupAuthType,
  LOGIN_OPTIONS,
  onFacebookConnect,
} from '@Libraries/login-signup-library';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {useLoginPageAuthType} from '@Components/login-page/login-page.hooks';
import {SignupForm} from '@Components/signup-form';
import {StudentLoginForm} from '@Components/student-login-form';
import {ConfirmLoginForm} from '@Components/confirm-login-form';
import {ForgotPasswordForm} from '@Components/forgot-password-form';
import {LoginForm} from '@Components/login-form';
import type {VoidFunction} from '@Components/login-page/login-page.types';
import {AuthFormFooterCta} from '@Components/auth-form-footer-cta';
import {Button, Size, Type} from '@Components/button';
import {SocialMediaIconsContainer} from '@Components/social-media-icons-container';
import {AppleLogin} from '@Components/apple-login';
import {useUpdateAuthFormTypes} from '@/hooks/login-signup/useUpdateAuthFormTypes';
import useWindowSize from '@/hooks/useWindowSize';
import styles from './login-page-content.module.scss';
import {useUpdateErrorStates} from '@/hooks/login-signup/useUpdateErrorStates';

interface LoginPageContentProps {
  onAuthTypeChangeCallback: VoidFunction;
  onPanelChange: VoidFunction;
  loginOptionsMode: LOGIN_OPTIONS;
  isUserJoiningTeam: boolean;
  clientName?: string;
  redirectUrl?: string;
}

const SMALL_SCREEN_HEIGHT_BREAKPOINT = 1000;

export function LoginPageContent({
  onAuthTypeChangeCallback,
  onPanelChange,
  loginOptionsMode,
  isUserJoiningTeam,
  clientName = '',
  redirectUrl = '',
}: LoginPageContentProps): ReactElement {
  const [showForm, setShowForm] = useState(false);
  const windowScreenSize = useWindowSize();

  const type = useLoginPageAuthType();
  const {updateAuthTypesAndClearErrors} = useUpdateAuthFormTypes();
  const {hideErrors} = useUpdateErrorStates();

  const updateStatesOnFormTypeChange = (formType: AUTH_TYPE): void => {
    updateAuthTypesAndClearErrors(formType, undefined, onAuthTypeChangeCallback);
  };

  const changeToSignupPage = (): void => {
    updateStatesOnFormTypeChange(AUTH_TYPE.SIGNUP);
  };

  const changeToLoginPage = (): void => {
    updateStatesOnFormTypeChange(AUTH_TYPE.LOGIN);
  };

  const changeToStudentLoginPage = (): void => {
    updateStatesOnFormTypeChange(AUTH_TYPE.STUDENT_LOGIN);
  };

  const changeToForgotPasswordPage = (): void => {
    updateStatesOnFormTypeChange(AUTH_TYPE.FORGOT_PASSWORD);
  };

  const getFormHeading = (): string => {
    switch (type) {
      case AUTH_TYPE.SIGNUP:
        return showForm ? window.i18next.t('pmwjs_separated_signup_with_email') : window.i18next.t('pmwjs_user_sign_up_heading');
      case AUTH_TYPE.STUDENT_LOGIN:
        return window.i18next.t('pmwjs_student_login_heading');
      case AUTH_TYPE.CONFIRM_LOGIN:
        return window.i18next.t('pmwjs_just_one_more_step');
      case AUTH_TYPE.FORGOT_PASSWORD:
        return window.i18next.t('pmwjs_login_forgot_password');
      case AUTH_TYPE.LOGIN:
        return getLoginFormHeading();
      default:
        return window.i18next.t('pmwjs_user_log_in');
    }
  };

  const getLoginFormHeading = (): string => {
    if (isOAuthFlow(loginOptionsMode)) {
      return window.i18next.t('pmwjs_user_log_in');
    }
    return showForm ? window.i18next.t('pmwjs_separated_login_with_email') : window.i18next.t('pmwjs_user_log_in');
  };

  const shouldBackButtonBeShown = (): boolean => {
    if (type === AUTH_TYPE.LOGIN || type === AUTH_TYPE.SIGNUP) {
      return showForm;
    }
    return doesAuthTypeNeedBackButton(type);
  };

  const onFormVisibilityChange = (): void => {
    onPanelChange();
    hideErrors();
  };

  const onBackButton = (): void => {
    switch (type) {
      case AUTH_TYPE.LOGIN:
      case AUTH_TYPE.SIGNUP:
        setShowForm(false);
        onFormVisibilityChange();
        break;
      default:
        changeToLoginPage();
    }
  };

  const getBackIcon = (): ReactElement | null => {
    if (shouldBackButtonBeShown()) {
      return (
        <Icon
          icon="icon-back"
          size={IconSize.SIZE_ICON_32}
          type={IconType.TRANSPARENT}
          shape={IconShape.SQUARE}
          onClick={onBackButton}
          className={`spacing-m-r-1 ${styles.headingIcon}`}
        />
      );
    }
    return null;
  };

  const onShowFormButtonClick = (): void => {
    setShowForm(true);
    onFormVisibilityChange();
  };

  const getShowFormButton = (): ReactNode => {
    return (
      <Button
        text={type === AUTH_TYPE.LOGIN ? window.i18next.t('pmwjs_user_continue_with_email') : window.i18next.t('pmwjs_separated_signup_with_email')}
        type={Type.PRIMARY}
        customClasses="-fullwidth spacing-m-t-3"
        icon="icon-envelope"
        size={Size.SMALL}
        onClick={onShowFormButtonClick}
      />
    );
  };

  const isSmallScreenLayout = (): boolean => {
    return isLoginOrSignupAuthType(type) && windowScreenSize.windowHeight < SMALL_SCREEN_HEIGHT_BREAKPOINT;
  };

  const shouldFormBeHidden = (): boolean => {
    return isSmallScreenLayout() && !showForm && !isOAuthFlow(loginOptionsMode);
  };

  const getForm = (): ReactNode => {
    if (shouldFormBeHidden()) {
      return getShowFormButton();
    }

    switch (type) {
      case AUTH_TYPE.SIGNUP:
        return <SignupForm />;
      case AUTH_TYPE.STUDENT_LOGIN:
        return <StudentLoginForm loginRedirect={changeToLoginPage} />;
      case AUTH_TYPE.CONFIRM_LOGIN:
        return <ConfirmLoginForm />;
      case AUTH_TYPE.FORGOT_PASSWORD:
        return <ForgotPasswordForm />;
      case AUTH_TYPE.LOGIN:
      default:
        return <LoginForm switchToForgotPassword={changeToForgotPasswordPage} loginOptionsMode={loginOptionsMode} />;
    }
  };

  const getActionItems = (): ReactNode => {
    switch (type) {
      case AUTH_TYPE.SIGNUP:
        return (
          <div className={styles.ctaButtons}>
            <AuthFormFooterCta text={window.i18next.t('pmwjs_user_login_prompt')} btnText={window.i18next.t('pmwjs_user_login_action')} onClick={changeToLoginPage} />
          </div>
        );
      case AUTH_TYPE.LOGIN:
      default:
        return (
          <div className={styles.ctaButtons}>
            <AuthFormFooterCta text={window.i18next.t('pmwjs_user_sign_up_prompt')} btnText={window.i18next.t('pmwjs_user_sign_up')} onClick={changeToSignupPage} />
            {loginOptionsMode === LOGIN_OPTIONS.DEFAULT ? (
              <AuthFormFooterCta
                text={window.i18next.t('pmwjs_student_login_prompt')}
                btnText={window.i18next.t('pmwjs_use_student_login')}
                icon="icon-graduation"
                onClick={changeToStudentLoginPage}
              />
            ) : null}
          </div>
        );
    }
  };

  const shouldThirdPartyButtonsBeShown = (): boolean => {
    return areThirdPartyButtonsNeededForType(type) && loginOptionsMode !== LOGIN_OPTIONS.OAUTH && !showForm;
  };

  const shouldAdditionalActionsBeShown = (): boolean => {
    return isLoginOrSignupAuthType(type) && !showForm;
  };

  const getHeadingTextClasses = (): string => {
    return window.PMW.util.isDefaultLanguage() ? `${styles.headingText} ${styles.noTextWrap}` : styles.headingText;
  };

  const getHeadingStyles = (): string => {
    return isOAuthFlow(loginOptionsMode) ? `spacing-m-b-2` : `spacing-m-b-8`;
  };

  const getOauthPageDefaultMessage = (): string => {
    return type === AUTH_TYPE.LOGIN ? window.i18next.t('pmwjs_oauth_login_page_default_message') : window.i18next.t('pmwjs_oauth_signup_page_default_message');
  };

  const getOauthPageClientMessage = (): string => {
    return type === AUTH_TYPE.LOGIN
      ? window.i18next.t('pmwjs_oauth_login_page_client_message', {clientName})
      : window.i18next.t('pmwjs_oauth_signup_page_client_message', {clientName});
  };

  const getOauthPageMessage = (): string => {
    return clientName ? getOauthPageClientMessage() : getOauthPageDefaultMessage();
  };

  const shouldOauthPageMessageBeShown = (): boolean => {
    return isOAuthFlow(loginOptionsMode) && isLoginOrSignupAuthType(type);
  };

  const isOauthSignupPage = (): boolean => {
    return isOAuthFlow(loginOptionsMode) && type === AUTH_TYPE.SIGNUP;
  };

  const getContainerStyles = (): string => {
    return isOauthSignupPage() ? `${styles.container} ${styles.oauthSignupContainer}` : `${styles.container}`;
  };

  const getGoogleRedirectUrl = (): string => {
    return isUserJoiningTeam ? redirectUrl : '';
  };

  return (
    <div className={getContainerStyles()}>
      <div className={`flex-row-align-center ${styles.heading} ${getHeadingStyles()}`}>
        {getBackIcon()}
        <Text val={getFormHeading()} className={getHeadingTextClasses()} />
      </div>

      <div className={shouldThirdPartyButtonsBeShown() ? '' : '_hidden'}>
        <GoogleLoginButton redirectUrl={getGoogleRedirectUrl()} containerClassname="spacing-m-b-3" />
        <ButtonFacebookLogin onConnect={onFacebookConnect.bind(null, redirectUrl)} isSignup={isSignupAuthType(type)} />
        <AppleLogin redirectUrl={redirectUrl} isSignup={isSignupAuthType(type)} />
        {!isSmallScreenLayout() ? <LineDivider className={styles.dividerContainer} dividerClassName={styles.orLineDivider} includeTextInDivider /> : null}
      </div>

      {shouldOauthPageMessageBeShown() ? (
        <div className="spacing-m-b-8">
          <Text val={getOauthPageMessage()} size={TextSize.SMALL} dangerouslySetInnerHTML />
        </div>
      ) : null}

      {getForm()}

      {shouldAdditionalActionsBeShown() ? (
        <>
          {isLoginOrSignupAuthType(type) ? <LineDivider dividerClassName={styles.lineDivider} /> : null}
          {getActionItems()}
          <LineDivider className="flex-row-justify-center" dividerClassName={`${styles.lineDivider} spacing-m-t-5`} />
          <SocialMediaIconsContainer />
        </>
      ) : null}
    </div>
  );
}
