import type {ReactNode} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {Text, TextSize} from '@Components/text';
import {resetNotificationBannerAnimation} from '@Components/login-page/login-page-reducer';
import {IconSize} from '@Components/icon-v2/icon.types';
import {useIsLoginToProceedMessage, useLoginPageAnimateNotificationBanner, useLoginPageErrorMessage, useLoginPageShowError} from '@Components/login-page/login-page.hooks';
import {useAppDispatch} from '@/hooks';
import styles from './notification-banner.module.scss';

interface NotificationBannerProps {
  showTeamWelcomeMessage: boolean;
  teamName?: string;
  isMobile?: boolean;
}

function NotificationBanner({showTeamWelcomeMessage, teamName = '', isMobile = false}: NotificationBannerProps) {
  const dispatch = useAppDispatch();
  const animateBanner = useLoginPageAnimateNotificationBanner();
  const showError = useLoginPageShowError();
  const errorMessage = useLoginPageErrorMessage();
  const isLoginToProceedMessage = useIsLoginToProceedMessage();

  const getContainerClasses = (): string => {
    let containerClasses = styles.errorMessageContainer;

    if (isLoginToProceedMessage) {
      containerClasses += ` ${styles.loginMessageContainer}`;
    }

    if (showError || showTeamWelcomeMessage) {
      return containerClasses;
    }
    return `${containerClasses} _hidden`;
  };

  const getMessageColorClass = (): string => {
    if (showTeamWelcomeMessage || isLoginToProceedMessage) {
      return styles.loginMessage;
    }
    return styles.errorText;
  };

  const getMessage = (): string => {
    return showTeamWelcomeMessage ? window.i18next.t('pmwjs_login_signup_to_join_team', {teamName}) : errorMessage;
  };

  const getSmallScreenContainerClass = (): string => {
    if (showError) {
      return styles.error;
    }
    return showTeamWelcomeMessage ? styles.teamWelcome : '_hidden';
  };

  const getNotificationBannerForSmallScreens = (): ReactNode => {
    return (
      <div
        className={`${styles.containerSmallScreen} ${getSmallScreenContainerClass()} ${getAnimationClasses()}`}
        onAnimationEnd={() => {
          dispatch(resetNotificationBannerAnimation());
        }}
      >
        <Text val={getMessage()} size={TextSize.XXSMALL} dangerouslySetInnerHTML className={getMessageColorClass()} />
      </div>
    );
  };

  const getAnimationClasses = (): string => {
    if (animateBanner) {
      return 'fast shake animated';
    }
    return '';
  };

  const getIconClassName = (): string => {
    if (showTeamWelcomeMessage || isLoginToProceedMessage) {
      return styles.loginMessageIcon;
    }

    return styles.errorMessageIcon;
  };

  const getStripClassName = (): string => {
    if (isLoginToProceedMessage || showTeamWelcomeMessage) {
      return styles.messageStrip;
    }

    return styles.errorStrip;
  };

  const getDefaultNotificationBanner = (): ReactNode => {
    return (
      <div
        className={`${getContainerClasses()} flex-center border-s-standard bg-neutral-0 ${getAnimationClasses()}`}
        onAnimationEnd={() => {
          dispatch(resetNotificationBannerAnimation());
        }}
      >
        <div className={`${styles.stripContainer} ${getStripClassName()}`} />
        <div className={'spacing-m-t-3 spacing-m-l-3 spacing-m-b-3 spacing-m-r-4 flex-center'}>
          <Icon icon={'icon-info'} size={IconSize.SIZE_ICON_20} className={getIconClassName()} />
          <Text size={TextSize.XSMALL} dangerouslySetInnerHTML val={getMessage()} className={getMessageColorClass()} />
        </div>
      </div>
    );
  };

  return <>{isMobile ? getNotificationBannerForSmallScreens() : getDefaultNotificationBanner()}</>;
}

export default React.memo(NotificationBanner);
