import type {ReactElement, ReactNode} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import styles from './login-page-ios.module.scss';
import {isOAuthFlow} from '@Libraries/login-signup-library';
import {initializeAuthFormParams, initializeoAuthFormParams, onFacebookConnect} from '@Libraries/login-signup-library';
import {useAppDispatch} from '@/hooks';
import {useLoginPageAnimateNotificationBanner, useLoginPageAuthType, useLoginPageShowError} from '@Components/login-page/login-page.hooks';
import {useOnRecaptchaRender} from '@Hooks/recaptcha/useOnRecaptchaRender';
import type {CommonAuthFormParams, LoginPageInitializeProps, OauthFormBaseParams} from '@Components/login-page/login-page.types';
import {LoginSignupPageIosAssetName} from '@Components/login-page/login-page.types';
import {initializeLoginPageState} from '@Components/login-page/login-page-reducer';
import {NotificationBanner} from '@Components/login-page/components/notification-banner';
import {LoginSignupPanelContainerIos} from '@Components/login-signup-panel-container-ios';
import {getAssetUrl} from '@Utils/s3.util';
import {PostermywallNavigationLogo} from '@Components/postermywall-navigation-logo';
import type {LoginPageProps} from '@Components/login-page';

export type LoginPageIosProps = LoginPageProps;

export function LoginPageIos({
  loginOptionsMode,
  loginActionType,
  signupActionType,
  isInDataRegulatedLocation,
  teamName = '',
  encryptedTeamName = '',
  idTeam = '',
  invite = '',
  redirectUrl = '',
  clientName = '',
  clientId = '',
  responseType = '',
  redirectUri = '',
  state = '',
  message = '',
  showErrorMessage = false,
  isTeamWelcomeMessage = false,
}: LoginPageIosProps): ReactElement | null {
  const [showTeamWelcomeMessage, setShowTeamWelcomeMessage] = useState(isTeamWelcomeMessage);
  const [pageAnimationClass, setPageAnimationClass] = useState('');

  const dispatch = useAppDispatch();
  const type = useLoginPageAuthType();
  const showError = useLoginPageShowError();
  const animateBanner = useLoginPageAnimateNotificationBanner();
  const pageRef = useRef<HTMLInputElement>(null);

  useOnRecaptchaRender();

  useEffect(() => {
    const initPageProps: LoginPageInitializeProps = {
      errorMessage: message,
      showError: showErrorMessage,
      isInDataRegulatedLocation: isInDataRegulatedLocation ?? false,
      signupActionType,
      loginActionType,
      redirectUrl,
      isLoginToProceedMessage: showErrorMessage,
      isOAuthFlow: isOAuthFlow(loginOptionsMode),
    };

    dispatch(initializeLoginPageState(initPageProps));
    const authFormParams: CommonAuthFormParams = {
      teamName: encryptedTeamName,
      idTeam,
      invite,
      redirecturl: redirectUrl,
      loginActionType,
      signupActionType,
    };
    initializeAuthFormParams(authFormParams);

    const oauthFormParams: OauthFormBaseParams = {
      clientId,
      clientName,
      responseType,
      redirectUri,
      state,
    };
    initializeoAuthFormParams(oauthFormParams);
  }, []);

  useEffect(() => {
    if (isTeamWelcomeMessage) {
      updateTeamWelcomeMessageVisibility();
    }
  }, [showError]);

  useEffect(() => {
    if (animateBanner) {
      scrollToTop();
    }
  }, [animateBanner]);

  const scrollToTop = (): void => {
    pageRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const enablePageAnimation = (): void => {
    setPageAnimationClass(styles.pageAnimation);
  };

  const updateTeamWelcomeMessageVisibility = (): void => {
    if (showError) {
      setShowTeamWelcomeMessage(false);
    } else {
      setShowTeamWelcomeMessage(true);
    }
  };

  const getLoginPageImage = (): ReactElement => {
    return <img className={styles.loginPageImage} loading="lazy" src={getAssetUrl(LoginSignupPageIosAssetName.PANEL_IMAGE)} alt="" style={{width: '286px', height: '190px'}} />;
  };

  const getLoginPageContent = (): ReactNode => {
    return (
      <div className={`${styles.mobileContentContainer} flex-column-align-center`}>
        {getLoginPageImage()}
        <div
          className={styles.panelContainer}
          onAnimationEnd={() => {
            setPageAnimationClass('');
          }}
        >
          <NotificationBanner showTeamWelcomeMessage={showTeamWelcomeMessage} teamName={teamName} isMobile />
          <LoginSignupPanelContainerIos
            isPanelInModal={false}
            ctaTextClasses="body-xs-bold"
            onPanelUpdate={enablePageAnimation}
            onFacebookConnect={onFacebookConnect.bind(null, redirectUrl)}
            panelType={type}
            loginOptionsMode={loginOptionsMode}
            className={pageAnimationClass}
          />
        </div>
      </div>
    );
  };

  const getBackgroundContent = (): ReactElement => {
    return (
      <div className={`${styles.container} _full-width _full-height flex-v-row`}>
        <div className={styles.backgroundContainer}>
          <div className={`${styles.backgroundOverlay} _full-width _full-height`} />
        </div>

        <div className={`flex-column-justify-center ${styles.logoContainer}`}>
          <PostermywallNavigationLogo imageUrl={window.PMW.util.asset_url('images/logo.png')} containerClassName={styles.logo} imageClassName={styles.logoImg} />
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.loginPageContainer} flex-h-row`}>
      <div className={`${styles.pageOverlay} _full-width _full-height`}>{getBackgroundContent()}</div>

      <div className={`${styles.formContainer} js-login-page-form-container flex-v-row flex-1`} ref={pageRef}>
        {getLoginPageContent()}
      </div>
    </div>
  );
}
