import type {ReactElement} from 'react';
import React, {type ReactNode} from 'react';
import styles from './login-signup-panel-container-ios.module.scss';
import {areThirdPartyButtonsNeededForType, AUTH_SUB_TYPE, AUTH_TYPE, isLoginAuthType, isMobileScreen, isOAuthFlow, LOGIN_OPTIONS} from '@Libraries/login-signup-library';
import type {FacebookConnectCallback, GoogleSignInCallback, VoidFunction} from '@Components/login-page/login-page.types';
import {noop} from '@Utils/general.util';
import useWindowSize from '@Hooks/useWindowSize';
import {useLoginPageAuthSubType, useLoginPageAuthType} from '@Components/login-page/login-page.hooks';
import {useUpdateAuthFormTypes} from '@Hooks/login-signup/useUpdateAuthFormTypes';
import {Button, Size, Type} from '@Components/button';
import {Text, TextSize} from '@Components/text';
import {AuthFormFooterCta} from '@Components/auth-form-footer-cta';
import {SignupForm} from '@Components/signup-form';
import {StudentLoginForm} from '@Components/student-login-form';
import {ForgotPasswordForm} from '@Components/forgot-password-form';
import {ConfirmLoginForm} from '@Components/confirm-login-form';
import {LoginForm} from '@Components/login-form';
import {ThirdPartyLoginButtons} from '@Components/login-signup-panel-container/components/third-party-login-buttons';
import {LoginSignupPanel} from '@Components/login-signup-panel-container/components/login-signup-panel';
import {LoginPanelHeadingIos} from '@Components/login-signup-panel-container-ios/components/login-panel-heading-ios';

interface LoginSignupPanelContainerIosProps {
  isPanelInModal: boolean;
  panelType: AUTH_TYPE;
  ctaTextClasses?: string;
  onSubmitSuccess?: VoidFunction;
  onPanelUpdate?: VoidFunction;
  onGoogleSignInDone?: GoogleSignInCallback;
  onAppleSignInDone?: (userId: string) => void;
  onFacebookConnect?: FacebookConnectCallback;
  showDivider?: boolean;
  loginOptionsMode?: LOGIN_OPTIONS;
  className?: string;
}

export function LoginSignupPanelContainerIos({
  isPanelInModal,
  panelType,
  ctaTextClasses = '',
  onSubmitSuccess = noop,
  onPanelUpdate = noop,
  onGoogleSignInDone = noop,
  onAppleSignInDone = noop,
  onFacebookConnect = noop,
  loginOptionsMode = LOGIN_OPTIONS.DEFAULT,
  className = '',
}: LoginSignupPanelContainerIosProps): ReactElement | null {
  const windowScreenSize = useWindowSize();
  const type = useLoginPageAuthType();
  const subType = useLoginPageAuthSubType();
  const {updateAuthTypesAndClearErrors} = useUpdateAuthFormTypes();

  const updateAuthFormType = (newAuthType: AUTH_TYPE, newAuthSubType?: AUTH_SUB_TYPE): void => {
    updateAuthTypesAndClearErrors(newAuthType, newAuthSubType, onPanelUpdate);
  };

  const switchToSignupPanelWithForm = (): void => {
    updateAuthFormType(AUTH_TYPE.SIGNUP, AUTH_SUB_TYPE.SIGNUP_WITH_FORM);
  };

  const switchToSignupPanelWithoutForm = (): void => {
    updateAuthFormType(AUTH_TYPE.SIGNUP, AUTH_SUB_TYPE.SIGNUP_WITHOUT_FORM);
  };

  const switchToLoginPanelWithForm = (): void => {
    updateAuthFormType(AUTH_TYPE.LOGIN, AUTH_SUB_TYPE.LOGIN_WITH_FORM);
  };

  const switchToLoginPanelWithoutForm = (): void => {
    updateAuthFormType(AUTH_TYPE.LOGIN, AUTH_SUB_TYPE.LOGIN_WITHOUT_FORM);
  };

  const switchToStudentLoginPanel = (): void => {
    updateAuthFormType(AUTH_TYPE.STUDENT_LOGIN);
  };

  const switchToForgotPasswordPanel = (): void => {
    updateAuthFormType(AUTH_TYPE.FORGOT_PASSWORD);
  };

  const getPanelHeadingText = (): string => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return shouldFormBeShown() ? window.i18next.t('pmwjs_signup_with_email') : window.i18next.t('pmwjs_user_sign_up_heading');
      case AUTH_TYPE.LOGIN:
        return shouldFormBeShown() ? window.i18next.t('pmwjs_login_with_email') : window.i18next.t('pmwjs_user_log_in');
      default:
        return '';
    }
  };

  const getPanelHeading = (): ReactElement | null => {
    if (isPanelInModal) {
      return null;
    }

    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return (
          <LoginPanelHeadingIos
            showBackOption={shouldBackIconBeShown()}
            changePanelLayout={switchToSignupPanelWithoutForm}
            heading={getPanelHeadingText()}
            linkActionInfo={window.i18next.t('pmwjs_user_login_prompt')}
            linkText={window.i18next.t('pmwjs_user_login_action')}
            switchPanel={switchToLoginPanelWithoutForm}
          />
        );
      case AUTH_TYPE.STUDENT_LOGIN:
        return <LoginPanelHeadingIos showBackOption={true} changePanelLayout={switchToLoginPanelWithoutForm} heading={window.i18next.t('pmwjs_student_login')} />;
      case AUTH_TYPE.CONFIRM_LOGIN:
        return <LoginPanelHeadingIos showBackOption={false} heading={window.i18next.t('pmwjs_just_one_more_step')} />;
      case AUTH_TYPE.FORGOT_PASSWORD:
        return <LoginPanelHeadingIos showBackOption={true} changePanelLayout={switchToLoginPanelWithForm} heading={window.i18next.t('pmwjs_login_forgot_password')} />;
      case AUTH_TYPE.LOGIN:
      default:
        return (
          <LoginPanelHeadingIos
            heading={getPanelHeadingText()}
            linkActionInfo={window.i18next.t('pmwjs_user_sign_up_prompt')}
            switchPanel={switchToSignupPanelWithoutForm}
            linkText={window.i18next.t('pmwjs_user_sign_up')}
            showBackOption={shouldBackIconBeShown()}
            changePanelLayout={switchToLoginPanelWithoutForm}
          />
        );
    }
  };

  const getCtaButtonSection = (): ReactElement | null => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return <div className="spacing-m-b-6 spacing-m-t-3">{getCtaButton(window.i18next.t('pmwjs_signup_with_email'))}</div>;
      case AUTH_TYPE.LOGIN:
        return <div className={`spacing-m-b-6  spacing-m-t-3 ${getLoginBtnClassname()}`}>{getCtaButton(window.i18next.t('pmwjs_user_continue_with_email'))}</div>;
      default:
        return null;
    }
  };

  const getLoginBtnClassname = (): string => {
    return isPanelInModal ? '' : styles.loginBtn;
  };

  const getCtaButton = (text: string): ReactNode => {
    return (
      <Button
        text={text}
        size={getActionButtonSize()}
        type={Type.SECONDARY}
        icon="icon-envelope"
        customClasses={`-fullwidth ${styles.ctaButton}`}
        textClasses={ctaTextClasses}
        onClick={isLoginAuthType(type) ? switchToLoginPanelWithForm : switchToSignupPanelWithForm}
      />
    );
  };

  const getActionButtonSize = (): Size => {
    return Size.SMALL;
  };

  const getAgreementTextSize = (): TextSize => {
    return TextSize.XXSMALL;
  };

  const getPanelFooter = (): ReactElement | null => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return getSignupPanelFooter();
      case AUTH_TYPE.LOGIN:
        return getLoginPanelFooter();
      default:
        return null;
    }
  };

  const getLoginPanelFooter = (): ReactElement => {
    return (
      <>
        {getSignUpActionItem()}
        <AuthFormFooterCta
          text={window.i18next.t('pmwjs_student_login_prompt')}
          btnText={window.i18next.t('pmwjs_use_student_login')}
          icon="icon-graduation"
          onClick={switchToStudentLoginPanel}
        />
      </>
    );
  };

  const getSignupPanelFooter = (): ReactElement => {
    return (
      <>
        <Text
          val={window.i18next.t('pmwjs_signup_terms_of_use', {
            link: window.PMW.util.site_url('info/termsofuse'),
            linkClass: `${styles.link} content-primary hover-transition-all`,
          })}
          className={`spacing-p-l-4 spacing-p-r-4 content-body text-center ${styles.signupPanelFooterText}`}
          dangerouslySetInnerHTML
          size={getAgreementTextSize()}
        />
        {getLoginActionItem()}
      </>
    );
  };

  const getSignUpActionItem = (): ReactNode => {
    if (isPanelInModal) {
      return <AuthFormFooterCta text={window.i18next.t('pmwjs_user_sign_up_prompt')} btnText={window.i18next.t('pmwjs_user_sign_up')} onClick={switchToSignupPanelWithoutForm} />;
    }
    return '';
  };

  const getLoginActionItem = (): ReactNode => {
    if (isPanelInModal) {
      return <AuthFormFooterCta text={window.i18next.t('pmwjs_user_login_prompt')} btnText={window.i18next.t('pmwjs_user_login_action')} onClick={switchToLoginPanelWithoutForm} />;
    }
    return '';
  };

  const getForm = (): ReactNode => {
    switch (panelType) {
      case AUTH_TYPE.SIGNUP:
        return <SignupForm onAuthFormSubmitSuccess={onSubmitSuccess} showSmallButton={isMobileScreen(windowScreenSize.windowWidth)} isFooterTextCenterAligned={true} />;
      case AUTH_TYPE.STUDENT_LOGIN:
        return (
          <StudentLoginForm
            isPanelScreen
            loginRedirect={switchToLoginPanelWithoutForm}
            signupRedirect={switchToSignupPanelWithoutForm}
            showSmallButton={true}
            onAuthFormSubmitSuccess={onSubmitSuccess}
          />
        );
      case AUTH_TYPE.FORGOT_PASSWORD:
        return <ForgotPasswordForm showSmallButton={isMobileScreen(windowScreenSize.windowWidth)} />;
      case AUTH_TYPE.CONFIRM_LOGIN:
        return <ConfirmLoginForm showSmallButton={isMobileScreen(windowScreenSize.windowWidth)} onPanelUpdate={onPanelUpdate} onAuthFormSubmitSuccess={onSubmitSuccess} />;
      case AUTH_TYPE.LOGIN:
      default:
        return (
          <LoginForm
            switchToForgotPassword={switchToForgotPasswordPanel}
            onAuthFormSubmitSuccess={onSubmitSuccess}
            showSmallButton={isMobileScreen(windowScreenSize.windowWidth)}
            onPanelUpdate={onPanelUpdate}
            loginOptionsMode={loginOptionsMode}
          />
        );
    }
  };

  const getContainerPadding = (): string => {
    if (isPanelInModal) {
      return styles.modalPanelContainer;
    }

    if (isOAuthFlow(loginOptionsMode) && !isNotLoginOrSignupPanel()) {
      return styles.optionsContainer;
    }

    if (shouldFormBeShown()) {
      return styles.formContainer;
    }

    return styles.optionsContainer;
  };

  const hideThirdPartyButtons = (): boolean => {
    return isPanelInModal && !areThirdPartyButtonsNeededForType(panelType);
  };

  const getThirdPartyButtons = (): ReactNode => {
    return (
      <ThirdPartyLoginButtons
        onFacebookConnect={onFacebookConnect}
        onGoogleSignInDone={onGoogleSignInDone}
        onAppleSignInDone={onAppleSignInDone}
        hideThirdPartyButtons={hideThirdPartyButtons()}
        googleCustomClassname={isPanelInModal ? '' : 'spacing-m-b-3'}
      />
    );
  };

  const shouldBackIconBeShown = (): boolean => {
    if (isNotLoginOrSignupPanel()) {
      return true;
    }

    if (isOAuthFlow(loginOptionsMode)) {
      return false;
    }

    return authSubTypeHasForm();
  };

  const shouldFormBeShown = (): boolean => {
    if (isNotLoginOrSignupPanel() || isOAuthFlow(loginOptionsMode)) {
      return true;
    }

    return authSubTypeHasForm();
  };

  const isNotLoginOrSignupPanel = (): boolean => {
    return panelType === AUTH_TYPE.STUDENT_LOGIN || panelType === AUTH_TYPE.FORGOT_PASSWORD || panelType === AUTH_TYPE.CONFIRM_LOGIN;
  };

  const authSubTypeHasForm = (): boolean => {
    return subType === AUTH_SUB_TYPE.LOGIN_WITH_FORM || subType === AUTH_SUB_TYPE.SIGNUP_WITH_FORM;
  };

  const getPanelContent = (): ReactElement => {
    if (shouldFormBeShown()) {
      return <div className={'_full-width _full-height'}>{getForm()}</div>;
    }

    return (
      <div>
        {getThirdPartyButtons()}
        {getCtaButtonSection()}
        {getPanelFooter()}
      </div>
    );
  };

  return (
    <div className={`_full-width ${className}`}>
      <div className={getContainerPadding()}>
        <LoginSignupPanel showForm={shouldFormBeShown()}>
          {getPanelHeading()}
          {getPanelContent()}
        </LoginSignupPanel>
      </div>
    </div>
  );
}
