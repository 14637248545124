import type {ReactElement, ReactNode} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {LoginSignupPanelContainer} from '@Components/login-signup-panel-container';
import {LoginSignupTestimony} from '@Components//login-signup-testimony';
import {NotificationBanner} from '@Components/login-page/components/notification-banner';
import {LoginPageContent} from '@Components/login-page/components/login-page-content';
import type {LOGIN_OPTIONS} from '@Libraries/login-signup-library';
import {AUTH_TYPE, initializeAuthFormParams, initializeoAuthFormParams, onFacebookConnect, isMobileScreen, isLoginAuthType, isOAuthFlow} from '@Libraries/login-signup-library';
import type {CommonAuthFormParams, LoginPageInitializeProps, OauthFormBaseParams} from '@Components/login-page/login-page.types';
import {useLoginPageAnimateNotificationBanner, useLoginPageAuthType, useLoginPageShowError} from '@Components/login-page/login-page.hooks';
import {initializeLoginPageState} from './login-page-reducer';
import {useAppDispatch} from '@/hooks';
import useWindowSize from '@/hooks/useWindowSize';
import {useOnRecaptchaRender} from '@/hooks/recaptcha/useOnRecaptchaRender';
import styles from './login-page.module.scss';

export interface LoginPageProps {
  loginOptionsMode: LOGIN_OPTIONS;
  loginActionType: string;
  signupActionType: string;
  isInDataRegulatedLocation: boolean;
  referrer?: string;
  teamName?: string;
  encryptedTeamName?: string;
  idTeam?: string;
  invite?: string;
  redirectUrl?: string;
  clientName?: string;
  clientId?: string;
  responseType?: string;
  redirectUri?: string;
  state?: string;
  message?: string;
  showErrorMessage?: boolean;
  isTeamWelcomeMessage?: boolean;
}

export function LoginPage({
  loginOptionsMode,
  loginActionType,
  signupActionType,
  isInDataRegulatedLocation,
  referrer = '',
  teamName = '',
  encryptedTeamName = '',
  idTeam = '',
  invite = '',
  redirectUrl = '',
  clientName = '',
  clientId = '',
  responseType = '',
  redirectUri = '',
  state = '',
  message = '',
  showErrorMessage = false,
  isTeamWelcomeMessage = false,
}: LoginPageProps): ReactElement {
  const [showTeamWelcomeMessage, setShowTeamWelcomeMessage] = useState(isTeamWelcomeMessage);
  const [pageAnimationClass, setPageAnimationClass] = useState('');

  const dispatch = useAppDispatch();
  const type = useLoginPageAuthType();
  const showError = useLoginPageShowError();
  const animateBanner = useLoginPageAnimateNotificationBanner();
  const windowScreenSize = useWindowSize();

  const pageRef = useRef<HTMLInputElement>(null);

  useOnRecaptchaRender();

  useEffect(() => {
    const initPageProps: LoginPageInitializeProps = {
      errorMessage: message,
      showError: showErrorMessage,
      isInDataRegulatedLocation: isInDataRegulatedLocation ?? false,
      signupActionType,
      loginActionType,
      redirectUrl,
      isLoginToProceedMessage: showErrorMessage,
      isOAuthFlow: isOAuthFlow(loginOptionsMode),
    };

    dispatch(initializeLoginPageState(initPageProps));

    const authFormParams: CommonAuthFormParams = {
      teamName: encryptedTeamName,
      idTeam,
      invite,
      redirecturl: redirectUrl,
      loginActionType,
      signupActionType,
    };

    initializeAuthFormParams(authFormParams);

    const oauthFormParams: OauthFormBaseParams = {
      clientId,
      clientName,
      responseType,
      redirectUri,
      state,
    };
    initializeoAuthFormParams(oauthFormParams);
  }, []);

  useEffect(() => {
    if (isTeamWelcomeMessage) {
      updateTeamWelcomeMessageVisibility();
    }
  }, [showError]);

  useEffect(() => {
    if (animateBanner) {
      scrollToTop();
    }
  }, [animateBanner]);

  const onAuthTypeChange = (): void => {
    scrollToTop();
    setPageAnimationClass(styles.pageAnimation);
  };

  const isUserJoiningTeam = (): boolean => {
    return idTeam !== '' && invite !== '' && teamName !== '';
  };

  const scrollToTop = (): void => {
    pageRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const doesReferrerExist = (): boolean => {
    return referrer !== undefined && referrer !== '';
  };

  const enablePageAnimation = (): void => {
    setPageAnimationClass(styles.pageAnimation);
  };

  const updateTeamWelcomeMessageVisibility = (): void => {
    if (showError) {
      setShowTeamWelcomeMessage(false);
    } else {
      setShowTeamWelcomeMessage(true);
    }
  };

  const getClassForFormType = (): string => {
    let formClassName = `${styles.form} ${styles.hideMobile}`;

    if (isLoginAuthType(type)) {
      formClassName += ` ${styles.loginForm}`;
    }
    if (type === AUTH_TYPE.SIGNUP) {
      formClassName += ` ${styles.signupForm}`;
    }

    if (showError) {
      return `${formClassName} ${styles.errorVisible}`;
    }
    return formClassName;
  };

  const getClasses = (): string => {
    return `${getClassForFormType()} flex-column-align-center ${pageAnimationClass}`;
  };

  const getLoginPageContent = (): ReactNode => {
    return isMobileScreen(windowScreenSize.windowWidth) ? (
      <div
        className={`${styles.panelContainer} ${pageAnimationClass}`}
        onAnimationEnd={() => {
          setPageAnimationClass('');
        }}
      >
        <NotificationBanner showTeamWelcomeMessage={showTeamWelcomeMessage} teamName={teamName} isMobile />
        <LoginSignupPanelContainer
          isPanelInModal={false}
          textClasses="body-xs-bold"
          onPanelUpdate={enablePageAnimation}
          onFacebookConnect={onFacebookConnect.bind(null, redirectUrl)}
          panelType={type}
          loginOptionsMode={loginOptionsMode}
        />
      </div>
    ) : (
      <>
        <NotificationBanner showTeamWelcomeMessage={showTeamWelcomeMessage} teamName={teamName} />
        <div
          className={getClasses()}
          onAnimationEnd={() => {
            setPageAnimationClass('');
          }}
        >
          <LoginPageContent
            onAuthTypeChangeCallback={onAuthTypeChange}
            onPanelChange={enablePageAnimation}
            loginOptionsMode={loginOptionsMode}
            isUserJoiningTeam={isUserJoiningTeam()}
            clientName={clientName}
            redirectUrl={redirectUrl}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`${styles.loginPageContainer} flex-h-row`}>
      <div className={styles.testimony}>
        <LoginSignupTestimony showTestimonies={!isMobileScreen(windowScreenSize.windowWidth)} doesReferrerExist={doesReferrerExist()} />
      </div>
      <div className={`${styles.formContainer} js-login-page-form-container flex-v-row flex-1`} ref={pageRef}>
        {getLoginPageContent()}
      </div>
    </div>
  );
}
