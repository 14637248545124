import React from 'react';

interface PostermywallLogoProps {
  imageUrl: string;
  containerClassName?: string;
  imageClassName?: string;
}

function PostermywallNavigationLogo({imageUrl, containerClassName = '', imageClassName = ''}: PostermywallLogoProps) {
  return (
    <div className={containerClassName}>
      <a href={window.PMW.util.homepage_url()} title={window.i18next.t('pmwjs_go_to_home_page')}>
        <img className={imageClassName} src={imageUrl} alt="PosterMyWall navigation logo" />
      </a>
    </div>
  );
}

export default React.memo(PostermywallNavigationLogo);
