import React, {useState} from 'react';
import {BLOGGER_TESTIMONY_INDEX, MUSIC_PROMOTER_TESTIMONY_INDEX, PRINCIPAL_TESTIMONY_INDEX} from '@Libraries/login-signup-library';
import {getAssetUrl} from '@Utils/s3.util';
import {LoginSignupPageAssetName} from '@Components/login-page/login-page.types';
import {TestimonyComment} from '../testimony-comment';
import styles from './testimony-transition.module.scss';

interface TestimonyTransitionProps {
  index: number;
}

const altText = ['music-promoter', 'blogger', 'principal'];

export function TestimonyTransition({index}: TestimonyTransitionProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  const getTestimonyImageAssetName = (testimonyIndex: number) => {
    switch (testimonyIndex) {
      case MUSIC_PROMOTER_TESTIMONY_INDEX:
        return LoginSignupPageAssetName.MUSIC_PROMOTER_TESTIMONY;
      case BLOGGER_TESTIMONY_INDEX:
        return LoginSignupPageAssetName.BLOGGER_TESTIMONY;
      case PRINCIPAL_TESTIMONY_INDEX:
        return LoginSignupPageAssetName.PRINCIPAL_TESTIMONY;
      default:
        return LoginSignupPageAssetName.MUSIC_PROMOTER_TESTIMONY;
    }
  };

  const getLoadedClass = (): string => {
    if (isLoaded) {
      return '';
    }
    return '_hidden';
  };

  return (
    <div className={styles.individualTestimonyContainer}>
      <div className={`${styles.flexContainer} flex-row-justify-center ${getLoadedClass()}`}>
        <img
          src={getAssetUrl(getTestimonyImageAssetName(index))}
          alt={`background-image-${altText[index]}`}
          aria-hidden
          className={styles.img}
          onLoad={() => {
            return setIsLoaded(true);
          }}
        />
      </div>

      <TestimonyComment index={index} quoteImageUrl={getAssetUrl(LoginSignupPageAssetName.QUOTE_ICON)} showComment={isLoaded} />
    </div>
  );
}
