import type {ReactElement} from 'react';
import React from 'react';
import type {BasePanel} from '@Components/panel/panel.types';
import {Panel, PanelHeader} from '@Components/panel';
import {AUTH_TYPE} from '@Libraries/login-signup-library';
import {ConnectAccountResetPasswordForm} from '@Panels/connect-sso-account-panel/components/connect-account-reset-password-form';
import {ConnectAccountLoginForm} from '@Panels/connect-sso-account-panel/components/connect-account-login-form';
import {ConnectAccountEmailRequiredForm} from '@Panels/connect-sso-account-panel/components/connect-account-email-required-form';
import type {VoidFunction} from '@Utils/general.util';
import {setIsNotConnectSSOAccountModal} from '@Components/login-page/login-page-reducer';
import {closePanel} from '@Components/panel/panel-reducer';
import useCustomPanelClose from '@Panels/hooks/useCustomPanelClose';
import {useUpdateAuthFormTypes} from '@Hooks/login-signup/useUpdateAuthFormTypes';
import {useLoginPageAuthType} from '@Components/login-page/login-page.hooks';
import {useAppDispatch} from '@/hooks';

export const CONNECT_SSO_ACCOUNT_PANEL_ID = 'connectSSOAccountPanel';

export interface ConnectSSOAccountPanelProps extends BasePanel {
  onConnectCallback: VoidFunction;
  isEmailRequired?: boolean;
  email?: string;
  errorMessage?: string;
  onPasswordRequiredCallback: VoidFunction;
  showSubscribeToEmailsCheckbox?: boolean;
}

export function ConnectSSOAccountPanel({...props}: ConnectSSOAccountPanelProps): ReactElement {
  const type = useLoginPageAuthType();
  const {updateAuthTypes} = useUpdateAuthFormTypes();
  const dispatch = useAppDispatch();
  const panelId = props.panelId ?? CONNECT_SSO_ACCOUNT_PANEL_ID;

  const close = (): void => {
    updateAuthTypes(AUTH_TYPE.LOGIN);
    dispatch(setIsNotConnectSSOAccountModal());
    dispatch(closePanel(panelId));
  };

  const onClose = useCustomPanelClose(panelId, close);

  const getContent = (): ReactElement => {
    if (props.isEmailRequired || props.showSubscribeToEmailsCheckbox) {
      return (
        <ConnectAccountEmailRequiredForm
          onConnectCallback={props.onConnectCallback}
          showSubscribeToEmailsCheckbox={props.showSubscribeToEmailsCheckbox}
          onPasswordRequiredCallback={props.onPasswordRequiredCallback}
        />
      );
    }
    if (isForgotPasswordPanel()) {
      return <ConnectAccountResetPasswordForm />;
    }

    return <ConnectAccountLoginForm email={props.email ?? ''} errorMessage={props.errorMessage ?? ''} onConnectCallback={props.onConnectCallback} />;
  };

  const isForgotPasswordPanel = (): boolean => {
    return type === AUTH_TYPE.FORGOT_PASSWORD;
  };

  const getPanelHeaderTitle = (): string => {
    if (props.isEmailRequired) {
      return window.i18next.t('pmwjs_email_required');
    }
    if (isForgotPasswordPanel()) {
      return window.i18next.t('pmwjs_reset_password');
    }
    return window.i18next.t('pmwjs_account_already_exists');
  };

  const onBackButtonClick = (): void => {
    updateAuthTypes(AUTH_TYPE.LOGIN);
    dispatch(setIsNotConnectSSOAccountModal());
  };

  return (
    <Panel
      panelHeader={<PanelHeader title={getPanelHeaderTitle()} onClose={onClose} showBackButton={isForgotPasswordPanel()} onBackButton={onBackButtonClick} />}
      panelContent={getContent()}
    />
  );
}
