import React from 'react';
import styles from './testimony-comment.module.scss';
import {Text, TextSize} from '../../../text';
import {TestimonyCommentFooter} from '../testimony-comment-footer';

interface TestimonyCommentProps {
  index: number;
  quoteImageUrl: string;
  showComment?: boolean;
}

const SHORT_TESTIMONY_INDEX = 1;

const reviews = [
  'My husband released his first single this year. I manage his artist page, website, promotional materials. PosterMyWall has been a complete LIFE SAVER! This site has exceeded my expectations.',
  'I needed to make customized menus for an event and I was so glad I found PosterMyWall. It was my first time using the website and it was so easy to use...',
  'PosterMyWall deserves a spot in every classroom in America - and beyond. I was the principal of a small rural school in Alabama. We did not have additional funding for visual publications...',
];

export function TestimonyComment({index, quoteImageUrl, showComment = false}: TestimonyCommentProps) {
  const getClassNameForTestimony = (): string => {
    if (index === SHORT_TESTIMONY_INDEX) {
      return styles.shortTestimony;
    }
    return styles.longTestimony;
  };

  const getVisibility = (): string => {
    return showComment ? '' : '_hidden';
  };

  return (
    <div className={`${styles.background} ${getVisibility()}`}>
      <div className={`${styles.iconContainer} flex-row-justify-center`}>
        <img src={quoteImageUrl} aria-hidden alt="review-icon" className={styles.img} />
      </div>

      <div className={`${styles.textContainer} content-body ${getClassNameForTestimony()}`}>
        <Text val={reviews[index]} size={TextSize.XSMALL} className={styles.text} />
      </div>

      <TestimonyCommentFooter index={index} />
    </div>
  );
}
